import Repository from './Repository'

const resource = '/api/shipping-companies'

export default {
  index (params) {
    return Repository.get(`${resource}`, { params })
  },
  show (id) {
    return Repository.get(`${resource}/${id}`)
  },
  store (body) {
    return Repository.post(`${resource}`, body)
  },
  update (id, body) {
    return Repository.put(`${resource}/${id}`, body)
  },
}
