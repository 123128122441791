<template>
  <div>
    <v-container
      fluid
    >
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            {{ $t('c.step1.title') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="step > 2"
            step="2"
          >
            {{ $t('c.step2.title') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="3">
            {{ $t('c.step3.title') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container>
              <div>
                <v-card>
                  <v-card-title
                    class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
                  >
                    {{ $t('cart') | capitalize }}
                  </v-card-title>
                  <parts-table
                    v-if="items.length"
                    class="mb-4"
                    :parts="items"
                  >
                    <template v-slot:quantity="{part}">
                      <div style="width: 100px">
                        <handle-item-amount :part="part" />
                      </div>
                    </template>
                    <template v-slot:missing="{part}">
                      <span v-if="part.entity_parts[0].stock_in - part.amount >= 0"> 0 </span>
                      <span
                        v-else
                        class="red--text"
                      > {{ part.entity_parts[0].stock_in - part.amount }} </span>
                    </template>
                  </parts-table>
                  <h3
                    v-else
                    class="pa-4"
                  >
                    {{ $t('c.cartIsEmpty') }}
                  </h3>
                </v-card>
              </div>
              <v-row class="mt-4 d-flex justify-end">
                <v-btn
                  v-if="items.length"
                  color="primary"
                  @click="step = 2"
                >
                  {{ $t('next') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-container>
              <div>
                <v-card>
                  <v-card-title
                    class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
                  >
                    {{ $t('client') | capitalize }}
                  </v-card-title>

                  <v-card-text>
                    <!--  select to handle error when the client is not selected-->
                    <v-select
                      v-if="!client"
                      :key="'id'"
                      v-model="client"
                      :hint="$t('c.select.hint')"
                      persistent-hint
                      item-text="name"
                      :loading="loading"
                      return-object
                      :items="clients"
                      :error-messages="errors.client"
                    />
                    <client-form
                      v-else
                      v-model="client"
                      :disabled="true"
                    >
                      <template v-slot:name>
                        <v-select
                          v-model="client"
                          :hint="$t('c.select.hint')"
                          persistent-hint
                          item-text="name"
                          return-object
                          :items="clients"
                          :error-messages="errors.client"
                        />
                      </template>
                    </client-form>
                  </v-card-text>
                </v-card>
              </div>
              <v-row class="mt-4 d-flex justify-end">
                <v-btn
                  class="mr-4"
                  @click="step--"
                >
                  {{ $t('back') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="handleStep2"
                >
                  {{ $t('next') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container>
              <div>
                <div>
                  <v-card>
                    <v-card-title
                      class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
                    >
                      {{ $t('cart') | capitalize }}
                    </v-card-title>
                    <parts-table
                      class="mb-4"
                      :parts="items"
                    >
                      <template v-slot:quantity="{part}">
                        {{ part.amount }}
                      </template>
                      <template v-slot:missing="{part}">
                        <span v-if="part.entity_parts[0].stock_in - part.amount >= 0"> 0 </span>
                        <span
                          v-else
                          class="red--text"
                        > {{ part.entity_parts[0].stock_in - part.amount }} </span>
                      </template>
                    </parts-table>
                  </v-card>
                </div>
              </div>

              <div class="mb-8">
                <v-card>
                  <v-card-title
                    class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
                  >
                    {{ $t('client') | capitalize }}
                  </v-card-title>
                  <client-form
                    v-if="client"
                    v-model="client"
                    :disabled="true"
                  />
                </v-card>
              </div>

              <div class="d-flex justify-end">
                <v-btn
                  class="mr-4"
                  :loading="loading"
                  @click="step--"
                >
                  {{ $t('back') }}
                </v-btn>
                <v-btn
                  class="mr-0"
                  color="primary"
                  :loading="loading"
                  @click="createOrder"
                >
                  {{ $t('create') }}
                </v-btn>
              </div>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>

import EntityClientsRepository from '@/api/EntityClientsRepository'
import ShippingCompaniesRepository from '@/api/ShippingCompaniesRepository'
import EntityOrdersRepository from '@/api/EntityOrdersRepository'
import PartsTable from '@/views/dashboard/components/Parts/PartsTable'
import ClientForm from '@/views/dashboard/components/Clients/ClientForm'
import HandleItemAmount from '@/views/dashboard/components/Parts/HandleItemAmount'

export default {
  name: 'Cart',
  components: { HandleItemAmount, ClientForm, PartsTable },
  data () {
    return {
      step: 1,
      clients: [],
      shippingCompanies: [],
      client: null,
      shippingCompany: null,
      loading: true,
      errors: {
        client: null,
      },
    }
  },
  computed: {
    items () {
      return this.$store.getters.getItems
    },
    user () {
      return this.$store.getters.getUserLogged
    },
    body () {
      return {
        partIds: this.items.map(item => item.id),
        quantities: this.items.map(item => item.amount),
        user_id: this.user.id,
        client_id: this.client.id,
      }
    },
  },
  created () {
    Promise.all([
      ShippingCompaniesRepository.index(),
      EntityClientsRepository.index(this.user.entity_id)])
      .then(([shippingCompanies, clients]) => {
        this.shippingCompanies = shippingCompanies.data.data
        this.clients = clients.data.data
        this.loading = false
      })
      .catch(() => {

      })
  },
  methods: {
    getPath (item) {
      return `http://parts.dvp${item.image}`
    },
    createOrder () {
      this.loading = true
      EntityOrdersRepository.store(this.user.entity_id, this.body)
        .then(() => {
          this.$store.dispatch('clearCart')
          this.$store.commit('snackBar/setSnack', {
            color: 'primary',
            message: this.$t('c.createdMessage'),
          })
          this.$router.push('/orders')
        })
        .catch(() => {
        })
    },
    handleStep2 () {
      if (this.client) {
        this.errors.client = null
        this.step = 3
      } else {
        this.errors.client = this.$t('c.errors.clientRequired')
      }
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          step1: {
            title: 'Review Cart',
          },
          step2: {
            title: 'Select Client',
          },
          step3: {
            title: 'Review Order',
          },
          select: {
            hint: 'Customer to whom the order will be sent',
            label: 'Client name',
          },
          errors: {
            clientRequired: 'Must select client,',
          },
          cartIsEmpty: 'Cart is empty',
          createdMessage: 'Order Created',
        },
      },
      it: {
        c: {
          step1: {
            title: 'Carrello revisione',
          },
          step2: {
            title: 'Seleziona cliente',
          },
          step3: {
            title: 'Ordine revisione',
          },
          select: {
            hint: 'Cliente a cui verrà inviato l\'ordine',
            label: 'Cliente nome',
          },
          errors: {
            clientRequired: 'Deve selezionare il cliente',
          },
          cartIsEmpty: 'Il carrello è vuoto',
          createdMessage: 'Ordine creato',
        },
      },
    },
  },

}
</script>

<style scoped>

</style>
